import React, { useState } from 'react';
import { Input, Button, Message, Checkbox } from '@arco-design/web-react';
import { motion } from 'framer-motion';
import { API_BASE_URL } from '../config';
import { IconClose } from '@arco-design/web-react/icon';
import { TITLE_COLOR } from '../App';
import { useLanguage } from '../contexts/LanguageContext';

const Login = ({ onSuccess, onClose }) => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [resetStep, setResetStep] = useState(1); // 1: 输入邮箱, 2: 验证码, 3: 新密码
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      
      const data = await response.json();
      
      if (response.status === 403 && data.needVerification) {
        Message.info(t('auth.messages.verificationRequired'));
        setShowVerification(true);
        setActiveTab('register');
        return;
      }
      
      if (!response.ok) throw new Error(data.error);
      
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('userEmail', email);
      localStorage.setItem('userId', data.user_id);
      Message.success(t('auth.messages.loginSuccess'));
      onSuccess?.();
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleRegister = async () => {
    try {
      if (!agreedToTerms) {
        Message.error(t('auth.terms.error'));
        return;
      }

      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
      
      Message.success(t('auth.messages.checkEmail'));
      setShowVerification(true);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerify = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/verify`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: verificationCode }),
      });
      
      const data = await response.json();
      if (!response.ok) throw new Error(data.error);
      
      Message.success(t('auth.messages.emailVerified'));
      setActiveTab('login');
      setShowVerification(false);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/resend-verification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
      });
      
      if (response.ok) {
        Message.success(t('auth.messages.codeSent'));
      } else {
        throw new Error(t('auth.errors.sendCodeFailed'));
      }
    } catch (error) {
      Message.error(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      
      if (!response.ok) throw new Error('Failed to send reset code');
      
      Message.success('Reset code has been sent to your email');
      setResetStep(2);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyResetCode = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/verify-reset-code`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: resetCode }),
      });
      
      if (!response.ok) throw new Error('Invalid reset code');
      
      setResetStep(3);
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/api/auth/reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          email,
          code: resetCode,
          newPassword 
        }),
      });
      
      if (!response.ok) throw new Error('Failed to reset password');
      
      Message.success('Password reset successful. Please login.');
      setShowForgotPassword(false);
      setActiveTab('login');
    } catch (error) {
      Message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const forgotPasswordButton = (
    <Button 
      type="text" 
      style={{ padding: 0, color: '#3491FA' }} 
      onClick={() => setShowForgotPassword(true)}
    >
      {t('auth.forgotPassword')}
    </Button>
  );

  const renderForgotPassword = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <h1 style={{
          textAlign: 'center',
          marginBottom: '30px',
          fontSize: '28px',
          fontWeight: 'bold',
          color: TITLE_COLOR
        }}>
          {t('auth.resetPassword')}
        </h1>
        
        {resetStep === 1 && (
          <>
            <Input
              placeholder={t('auth.email')}
              value={email}
              onChange={setEmail}
              style={{ height: '40px' }}
              autoComplete="username"
            />
            <Button 
              type="primary"
              loading={loading}
              onClick={handleForgotPassword}
              style={{
                height: '40px',
                borderRadius: '8px',
                background: '#3491FA',
                border: 'none'
              }}
            >
              {t('auth.sendResetCode')}
            </Button>
          </>
        )}

        {resetStep === 2 && (
          <>
            <Input
              placeholder={t('auth.verificationCode')}
              value={resetCode}
              onChange={setResetCode}
              style={{ height: '40px' }}
            />
            <Button 
              type="primary"
              loading={loading}
              onClick={handleVerifyResetCode}
              style={{
                height: '40px',
                borderRadius: '8px',
                background: '#3491FA',
                border: 'none'
              }}
            >
              {t('auth.verifyCode')}
            </Button>
          </>
        )}

        {resetStep === 3 && (
          <>
            <Input.Password
              placeholder={t('auth.newPassword')}
              value={newPassword}
              onChange={setNewPassword}
              style={{ height: '40px' }}
              autoComplete="new-password"
            />
            <Button 
              type="primary"
              loading={loading}
              onClick={handleResetPassword}
              style={{
                height: '40px',
                borderRadius: '8px',
                background: '#3491FA',
                border: 'none'
              }}
            >
              {t('auth.resetPassword')}
            </Button>
          </>
        )}

        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <Button 
            type="text" 
            style={{ color: '#3491FA' }} 
            onClick={() => {
              setShowForgotPassword(false);
              setActiveTab('login');
            }}
          >
            {t('auth.backToLogin')}
          </Button>
        </div>
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 'login') {
      handleLogin();
    } else {
      handleRegister();
    }
  };

  return (
    <>
      {/* 背景遮罩 */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(4px)',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={onClose}
      >
        {/* 登录卡片 */}
        <motion.div
          initial={{ opacity: 0, y: 20, scale: 0.95 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: 20, scale: 0.95 }}
          transition={{ 
            duration: 0.3,
            ease: [0.4, 0, 0.2, 1]
          }}
          style={{
            width: '90%',
            maxWidth: '400px',
            zIndex: 1001,
          }}
          onClick={e => e.stopPropagation()}  // 防止点击卡片时触发背景点击事件
        >
          <div style={{
            background: 'white',
            borderRadius: '24px',
            padding: '40px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            position: 'relative'
          }}>
            <Button
              type="text"
              icon={<IconClose />}
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                padding: '4px',
                minWidth: 'auto'
              }}
              onClick={onClose}
            />

            <h1 style={{
              textAlign: 'center',
              marginBottom: '30px',
              fontSize: '28px',
              fontWeight: 'bold',
              color: TITLE_COLOR
            }}>
              {showVerification ? t('auth.verifyEmail') : 
               (activeTab === 'login' ? t('auth.login') : t('auth.signup'))}
            </h1>

            {showVerification ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Input
                  placeholder={t('auth.email')}
                  value={email}
                  disabled
                  autoComplete="username"
                />
                <Input
                  placeholder={t('auth.verificationCode')}
                  value={verificationCode}
                  onChange={setVerificationCode}
                />
                <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  <Button type="primary" loading={loading} onClick={handleVerify} style={{ flex: 1 }}>
                    {t('auth.verifyEmail')}
                  </Button>
                  <Button type="text" onClick={handleResendVerification}>
                    {t('auth.resendCode')}
                  </Button>
                </div>
              </div>
            ) : showForgotPassword ? (
              renderForgotPassword()
            ) : (
              <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Input
                  placeholder={t('auth.email')}
                  value={email}
                  onChange={setEmail}
                  style={{ height: '40px' }}
                  autoComplete="username"
                />
                <Input.Password
                  placeholder={t('auth.password')}
                  value={password}
                  onChange={setPassword}
                  style={{ height: '40px' }}
                  autoComplete={activeTab === 'login' ? "current-password" : "new-password"}
                />
                {activeTab === 'login' && (
                  <div style={{ textAlign: 'right', marginTop: '-5px' }}>
                    {forgotPasswordButton}
                  </div>
                )}
                {activeTab === 'register' && (
                  <div style={{ marginTop: '10px' }}>
                    <Checkbox 
                      checked={agreedToTerms}
                      onChange={(checked) => setAgreedToTerms(checked)}
                    >
                      {t('auth.terms.agree')}{' '}
                      <Button 
                        type="text" 
                        style={{ 
                          padding: '0 4px', 
                          color: '#3491FA',
                          height: 'auto',
                          lineHeight: 'inherit' 
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open('/terms', '_blank');
                        }}
                      >
                        {t('auth.terms.link')}
                      </Button>
                      {' and '}
                      <Button 
                        type="text" 
                        style={{ 
                          padding: '0 4px', 
                          color: '#3491FA',
                          height: 'auto',
                          lineHeight: 'inherit' 
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open('/privacy', '_blank');
                        }}
                      >
                        Privacy Policy
                      </Button>
                    </Checkbox>
                  </div>
                )}
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading} 
                  style={{
                    marginTop: '10px',
                    height: '40px',
                    borderRadius: '8px',
                    background: '#3491FA',
                    border: 'none'
                  }}
                  disabled={activeTab === 'register' && !agreedToTerms}
                >
                  {activeTab === 'login' ? t('auth.login') : t('auth.signup')}
                </Button>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  {activeTab === 'login' ? (
                    <span>{t('auth.noAccount')}<Button type="text" style={{ color: '#3491FA' }} onClick={() => setActiveTab('register')}>{t('auth.signup')}</Button></span>
                  ) : (
                    <span>{t('auth.haveAccount')}<Button type="text" style={{ color: '#3491FA' }} onClick={() => setActiveTab('login')}>{t('auth.login')}</Button></span>
                  )}
                </div>
              </form>
            )}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Login;