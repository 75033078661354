import React from 'react';
import { Typography } from '@arco-design/web-react';
import { useLanguage } from '../contexts/LanguageContext';

const { Title, Paragraph } = Typography;

const Terms = () => {
  const { t, currentLanguage } = useLanguage();
  
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '40px auto',
      padding: '20px',
      minHeight: '100vh'
    },
    section: {
      marginBottom: '32px'
    },
    title: {
      marginBottom: '16px',
      fontWeight: 'bold'
    },
    content: {
      fontSize: '16px',
      lineHeight: '1.8',
      color: '#333',
      marginBottom: '16px',
      wordBreak: 'keep-all'
    },
    list: {
      marginLeft: '20px',
      marginBottom: '16px',
      wordBreak: 'keep-all'
    },
    email: {
      color: '#3491FA',
      textDecoration: 'none'
    }
  };

  const renderContent = (content, styles) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <div key={index} style={{ marginBottom: '24px' }}>
          <Title heading={4} style={styles.subTitle}>
            {item.title}
          </Title>
          <Paragraph style={styles.content}>
            {item.content}
          </Paragraph>
          {item.items && (
            <ul style={styles.list}>
              {item.items.map((listItem, i) => (
                <li key={i} style={styles.listItem}>{listItem}</li>
              ))}
            </ul>
          )}
        </div>
      ));
    }
    
    if (typeof content === 'string') {
      return <Paragraph style={styles.content}>{content}</Paragraph>;
    }
    
    return null;
  };

  const renderSection = (section, styles) => {
    return (
      <div style={styles.section}>
        <Title heading={3} style={styles.sectionTitle}>
          {section.title}
        </Title>
        {renderContent(section.content, styles)}
        {section.items && (
          <ul style={styles.list}>
            {section.items.map((item, i) => (
              <li key={i} style={styles.listItem}>
                <strong style={{ fontWeight: '600' }}>{item.title}: </strong>
                {item.content}
              </li>
            ))}
          </ul>
        )}
        {section.email && (
          <Paragraph style={styles.content}>
            {section.content}{' '}
            <a href={`mailto:${section.email}`} style={styles.email}>
              {section.email}
            </a>
          </Paragraph>
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <Title heading={2} style={styles.mainTitle}>
        {t('auth.terms.link')}
      </Title>
      
      <Paragraph style={{...styles.content, marginBottom: '32px'}}>
        {t('terms.lastUpdated')}: {new Date().toLocaleDateString(currentLanguage === 'zh' ? 'zh-CN' : 'en-US')}
      </Paragraph>

      {Object.values(t('terms.sections')).map((section, index) => 
        renderSection(section, styles)
      )}
    </div>
  );
};

export default Terms;