export const translations = {
    en: {
      nav: {
        features: 'Features',
        pricing: 'Pricing',
        login: 'Login',
        signup: 'Sign Up',
        logout: 'Logout',
        tokens: 'Tokens'
      },
      hero: {
        trusted: 'Trusted',
        bypass: 'Bypass AI Detection',
        tool: 'Tool',
        description: 'Advanced algorithms that transform your AI-generated text into human-like text, bypassing AI detection checks for coursework assignments and academic papers.'
      },
      aiChecker: {
        detector: 'Detector',
        detectors: {
          turnitin: 'Turnitin(English)',
          cnki: 'CNKI(Chinese)'
        },
        steps: {
          understand: 'Understand Input',
          check: 'Check AI Content',
          compute: 'Start Computing',
          final: 'Final Check'
        },
        buttons: {
          startRewriting: 'Start Rewriting',
          paste: 'Paste Text',
          sample: 'Try Sample',
          copy: 'Copy',
          undo: 'Undo'
        },
        messages: {
          waiting: 'waiting...',
          maxLength: '1000',
          copied: 'Copied to clipboard'
        },
        errors: {
          loginRequired: 'Please login to continue',
          emptyText: 'Please enter text',
          insufficientWords: 'Insufficient word balance. You need {required} words but only have {remaining} remaining. Please purchase more words.',
          clipboardAccess: 'Cannot access clipboard, please paste manually',
          checkoutFailed: 'Failed to create checkout session'
        },
        placeholder: 'Enter text detected as AI-generated...'
      },
      features: {
        title: 'Features',
        detection: {
          title: 'Significantly Reduce Detection Rates',
          description: 'Our sophisticated algorithms have been extensively tested to guarantee that even fully AI-generated text clears Turnitin and CNKI checks with ease.'
        },
        rewriting: {
          title: 'High-Quality Human-Like Rewriting',
          description: 'Unlike other tools, our algorithms preserve the core meaning without introducing errors or complex jargon, keeping text coherent and sophisticated.'
        }
      },
      pricing: {
        title: 'Pricing Plans',
        bestDeal: 'BEST DEAL',
        tokens: 'tokens',
        pay: 'Pay',
        errors: {
          checkoutFailed: 'Failed to create checkout session'
        }
      },
      auth: {
        login: 'Log In',
        signup: 'Sign Up',
        email: 'E-Mail',
        password: 'Password',
        forgotPassword: 'Forgot password?',
        noAccount: 'Don\'t have an account?',
        hasAccount: 'Already have an account?',
        verifyEmail: 'Verify Email',
        verificationCode: 'Verification Code',
        resendCode: 'Resend Code',
        resetPassword: 'Reset Password',
        newPassword: 'New Password',
        backToLogin: 'Back to Login',
        sendResetCode: 'Send Reset Code',
        verifyCode: 'Verify Code',
        messages: {
          verificationRequired: 'Account not verified. Verification code has been sent to your email.',
          loginSuccess: 'Login successful',
          checkEmail: 'Please check your email for verification code',
          emailVerified: 'Email verified successfully. Please login.',
          codeSent: 'New verification code sent'
        },
        errors: {
          sendCodeFailed: 'Failed to send verification code'
        },
        terms: {
          agree: 'I agree to the',
          link: 'Terms of Service',
          error: 'Please agree to the Terms of Service and Privacy Policy'
        }
      },
      payment: {
        success: {
          title: 'Payment Successful!',
          subtitle: 'Your tokens have been added to your account.',
          backHome: 'Back to Home'
        }
      },
      terms: {
        lastUpdated: 'Last updated',
        sections: {
          introduction: {
            title: 'Introduction',
            content: 'Welcome to Ushallpass.ai! Our service provides artificial intelligence tools designed to rewrite text flagged by AI detectors, rendering it undetectable. By accessing and using our services, you agree to comply with and be bound by the following terms and conditions. Please review them carefully before using the services.'
          },
          eligibility: {
            title: 'Eligibility',
            content: 'You must be at least 18 years of age to use Ushallpass.ai, or possess legal parental or guardian consent if under the age of 18.'
          },
          serviceUsage: {
            title: 'Service Usage',
            content: [
              {
                title: 'License',
                content: 'We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our services strictly in accordance with these terms.'
              },
              {
                title: 'Prohibited Use',
                content: 'The service must not be used for any illegal or unauthorized purposes. Specifically, you agree not to use the service to:',
                items: [
                  'Facilitate or promote illegal activities',
                  'Infringe upon the intellectual property rights of others or distribute copyright-infringing materials',
                  'Evade AI detection techniques for deceptive or harmful purposes'
                ]
              }
            ]
          },
          userResponsibilities: {
            title: 'User Responsibilities',
            items: [
              {
                title: 'Account Security',
                content: 'You are responsible for maintaining the confidentiality of your account login information and are liable for all activities that occur under your account.'
              },
              {
                title: 'Content Submission',
                content: 'You assume full responsibility for the accuracy of the text submitted and the output generated by our services.'
              },
              {
                title: 'Payments',
                content: 'Service fees must be paid to access enhanced features and services. You agree to ensure timely and complete payments using a valid payment method.'
              }
            ]
          },
          intellectualProperty: {
            title: 'Intellectual Property Rights',
            content: 'All content, including but not limited to the software, designs, text, images, and databases, generated by or connected with Ushallpass.ai, is protected by copyright and international intellectual property laws. You are strictly prohibited from copying, modifying, or distributing any content without express written permission from us.'
          },
          liability: {
            title: 'Limitation of Liability',
            content: 'Ushallpass.ai is not liable for any direct or indirect, incidental, consequential, special, or exemplary damages arising from your use or inability to use the services, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.'
          },
          dispute: {
            title: 'Dispute Resolution',
            content: 'Disputes arising under these terms will be resolved exclusively through final and binding arbitration under the rules of the American Arbitration Association.'
          },
          amendments: {
            title: 'Amendments to Terms',
            content: 'We reserve the right to modify these terms at any time. Such modifications become effective immediately upon posting. Your continued use of our services following any such changes shall constitute your consent to such changes.'
          },
          contact: {
            title: 'Contact Information',
            content: 'For any questions or concerns about these terms, please contact us at',
            email: 'contact@ushallpass.ai'
          }
        }
      },
      privacy: {
        lastUpdated: 'Last Updated',
        sections: {
          introduction: {
            title: 'Privacy Policy',
            content: 'Welcome to UShallPass.ai. Your privacy is critically important to us. This Privacy Policy outlines the types of personal information we collect, how it is used, and the measures we take to protect it.'
          },
          informationCollection: {
            title: 'Information We Collect',
            content: [
              {
                title: 'User-Provided Information',
                content: 'We collect the text you provide for rewriting as well as your email address if you choose to subscribe to our newsletter.'
              },
              {
                title: 'Automated Information',
                content: 'We gather information like IP address, browser type, and usage statistics through standard analytics tools.'
              }
            ]
          },
          useOfInformation: {
            title: 'Use of Information',
            content: [
              {
                title: 'Service Provision',
                content: 'The text provided by you is used solely for the purpose of rewriting.'
              },
              {
                title: 'Communication',
                content: 'We may use your email to communicate service updates or promotional offers if you opt-in.'
              },
              {
                title: 'Improvement and Maintenance',
                content: 'Analytics data helps us enhance user experience and identify system needs.'
              }
            ]
          },
          dataSharing: {
            title: 'Data Sharing',
            content: 'We do not sell or rent your personal data to third parties. Data may be shared with service providers under strict confidentiality agreements for the purpose of operating our service, and only as legally required.'
          },
          dataSecurity: {
            title: 'Data Security',
            content: 'We implement a variety of security measures to maintain the safety of your personal information, including encryption and secure server configurations.'
          },
          userRights: {
            title: 'User Rights',
            content: 'You have the right to access, correct, or delete your personal data stored with us. Please contact us at info@ushallpass.ai to exercise any of these rights.'
          },
          changes: {
            title: 'Changes to Privacy Policy',
            content: 'We may modify this policy from time to time. All updates will be posted on this page with an updated revision date.'
          }
        }
      },
      footer: {
        terms: 'Terms of Service',
        privacy: 'Privacy Policy'
      }
    },
    zh: {
      nav: {
        features: '功能特点',
        pricing: '价格方案',
        login: '登录',
        signup: '注册',
        logout: '退出登录',
        tokens: '剩余字数'
      },
      hero: {
        trusted: '值得信赖的',
        bypass: '降低AI检测率',
        tool: '工具',
        description: '经过验证的对抗算法可以将AIGC生成文本痕迹消除，生产出类人文本的同时保持原意和学术性表达，高效通过Turnitin和CNKI等AI检测。'
      },
      aiChecker: {
        detector: '检测器',
        detectors: {
          turnitin: 'Turnitin(英文)',
          cnki: '知网(中文)'
        },
        steps: {
          understand: '理解输入',
          check: '检查AI痕迹',
          compute: '开始计算',
          final: '最终检查'
        },
        buttons: {
          startRewriting: '开始改写',
          paste: '粘贴文本',
          sample: '试用示例',
          copy: '复制',
          undo: '撤销'
        },
        messages: {
          waiting: '等待中...',
          maxLength: '1000',
          copied: '已复制到剪贴板'
        },
        errors: {
          loginRequired: '请先登录',
          emptyText: '请输入文本',
          insufficientWords: '字数不足。当前文本需要 {required} 字，但您仅剩 {remaining} 字。请购买更多字数。',
          clipboardAccess: '无法访问剪贴板，请手动粘贴文本',
          checkoutFailed: '创建支付会话失败'
        },
        placeholder: '请输入需要处理的AI生成文本...'
      },
      features: {
        title: '功能特点',
        detection: {
          title: '显著降低检测率',
          description: '我们的算法经过广泛测试，即使是完全由AI生成的文本，也能被消除AIGC生成痕迹，轻松通过Turnitin和CNKI的检查。'
        },
        rewriting: {
          title: '保证原意和学术质量',
          description: '与其他改写工具不同，我们的算法在改写的同时不会破坏语义，不会可疑引入错误、复杂术语或拆分简单句，保持文本连贯，符合学术标准。'
        }
      },
      pricing: {
        title: '价格方案',
        bestDeal: '最优选择',
        tokens: '字数',
        pay: '支付',
        errors: {
          checkoutFailed: '创建支付会话失败'
        }
      },
      auth: {
        login: '登录',
        signup: '注册',
        email: '邮箱',
        password: '密码',
        forgotPassword: '忘记密码？',
        noAccount: '没有账号？',
        hasAccount: '已有账号？',
        verifyEmail: '验证邮箱',
        verificationCode: '验证码',
        resendCode: '重新发送',
        resetPassword: '重置密码',
        newPassword: '新密码',
        backToLogin: '返回登录',
        sendResetCode: '发送重置码',
        verifyCode: '验证验证码',
        messages: {
          verificationRequired: '账号未验证。验证码已发送至您的邮箱。',
          loginSuccess: '登录成功',
          checkEmail: '请查看邮箱获取验证码',
          emailVerified: '邮箱验证成功，请登录。',
          codeSent: '新的验证码已发送'
        },
        errors: {
          sendCodeFailed: '发送验证码失败'
        },
        terms: {
          agree: '我同意',
          link: '服务条款和隐私政策',
          error: '请同意服务条款和隐私政策'
        }
      },
      payment: {
        success: {
          title: '支付成功！',
          subtitle: '字数已添加到您的账户。',
          backHome: '返回首页'
        }
      },
      terms: {
        lastUpdated: '最后更新时间',
        sections: {
          introduction: {
            title: '引言',
            content: '欢迎使用Ushallpass.ai！我们的服务提供专门设计的AI工具，用于重写被AI检测器标记的文本，使之不被探测到。访问和使用我们的服务即表示您同意遵守并受以下条款和条件的约束。使用服务前，请仔细阅读这些条款。'
          },
          eligibility: {
            title: '适用对象',
            content: '您须至少达到18岁才能使用Ushallpass.ai，若未满18岁，则必须拥有法定父母或监护人的同意。'
          },
          serviceUsage: {
            title: '服务使用',
            content: [
              {
                title: '许可权',
                content: '我们授予您有限的、非排他性的、不可转让的、可撤销的许可，按照这些条款严格访问和使用我们的服务。'
              },
              {
                title: '禁止使用',
                content: '服务不得用于任何非法或未授权的目的。特别是，您同意不将服务用于：',
                items: [
                  '促进或支持违法活动',
                  '侵犯他人的知识产权或分发侵权材料',
                  '为欺诈或有害目的规避AI检测技术'
                ]
              }
            ]
          },
          userResponsibilities: {
            title: '用户责任',
            items: [
              {
                title: '账户安全',
                content: '您负责保持账户登录信息的机密性，并对您账户下发生的所有活动负责。'
              },
              {
                title: '内容提交',
                content: '您需对提交的文本及我们服务生成的输出承担全部责任。'
              },
              {
                title: '支付',
                content: '必须支付服务费用以访问增强功能和服务。您同意使用有效的支付方式确保及时、完整地支付。'
              }
            ]
          },
          intellectualProperty: {
            title: '知识产权',
            content: '所有内容，包括但不限于软件、设计、文字、图像和数据库，均受版权和国际知识产权法律保护。未经我们明确的书面许可，严禁复制、修改或分发任何内容。'
          },
          liability: {
            title: '责任限制',
            content: 'Ushallpass.ai对由于使用或无法使用服务引起的任何直接或间接的偶然的、特殊的或示范性的损害均不承担责任，包括但不限于利润损失、商誉、使用、数据或其他无形损失。'
          },
          dispute: {
            title: '争议解决',
            content: '根据这些条款产生的争议将通过美国仲裁协会的规则独家解决。'
          },
          amendments: {
            title: '条款修改',
            content: '我们保留随时修改这些条款的权利。一旦通过发布实施此类修改，则立即生效。您继续使用我们的服务即表示您接受此类更改。'
          },
          contact: {
            title: '联系方式',
            content: '如有任何关于这些条款的问题或顾虑，请通过',
            email: 'contact@ushallpass.ai'
          }
        }
      },
      privacy: {
        lastUpdated: '最后更新时间',
        sections: {
          introduction: {
            title: '隐私政策',
            content: '欢迎来到UShallPass.ai。您的隐私对我们至关重要。本隐私政策概述了我们收集的个人信息类型、使用方式以及我们采取的保护措施。'
          },
          informationCollection: {
            title: '我们收集的信息',
            content: [
              {
                title: '用户提供信息',
                content: '我们收集您提供用于改写的文本以及您选择订阅时提供的电子邮件地址。'
              },
              {
                title: '自动化信息',
                content: '通过标准分析工具收集IP地址、浏览器类型和使用统计信息。'
              }
            ]
          },
          useOfInformation: {
            title: '信息的使用',
            content: [
              {
                title: '服务提供',
                content: '您提供的文本仅用于改写目的。'
              },
              {
                title: '沟通',
                content: '如果您选择接受我们可能会使用您的电子邮件与您通信，提供服务更新或促销优惠。'
              },
              {
                title: '改进和维护',
                content: '分析数据帮助我们提升用户体验并识别系统需求。'
              }
            ]
          },
          dataSharing: {
            title: '数据共享',
            content: '我们不会将您的个人数据出售或出租给第三方。数据可能在严格保密协议的前提下与服务提供商共享，仅为了运营我们的服务，并且只在法律要求的情况下共享。'
          },
          dataSecurity: {
            title: '数据安全',
            content: '我们实施多种安全措施来保持您的个人信息安全，包括加密和安全服务器配置。'
          },
          userRights: {
            title: '用户权利',
            content: '您有权访问、更正或删除我们存储的您的个人资料。请通过info@ushallpass.ai与我们联系以行使任何这些权利。'
          },
          changes: {
            title: '隐私政策变更',
            content: '我们可能会不时修改此政策。所有更新将发布在此页面上，并更新修订日期。'
          }
        }
      },
      footer: {
        terms: '服务条款和隐私政策',
        privacy: '隐私政策'
      }
    }
  };