import React, { useState, useEffect, useCallback } from 'react';
import { ConfigProvider, Typography, Button, Dropdown, Menu } from '@arco-design/web-react';
import { motion, AnimatePresence } from 'framer-motion';
import AITextChecker from './components/AITextChecker';
import Features from './components/Features';
import Pricing from './components/Pricing';
import '@arco-design/web-react/dist/css/arco.css';
import Login from './components/Login';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { IconMenu } from '@arco-design/web-react/icon';
import { useMediaQuery } from '@react-hook/media-query';
import { API_BASE_URL } from './config';
import PaymentSuccess from './components/PaymentSuccess';
import { useLanguage } from './contexts/LanguageContext';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Footer from './components/Footer';

const { Title, Paragraph } = Typography;

export const TITLE_COLOR = '#001A4D';

const typingContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.035,
      delayChildren: 1
    }
  }
};

const typingCharacter = {
  hidden: { opacity: 0, y: 0 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1
    }
  }
};

const scrollToElement = (elementId) => (e) => {
  e.preventDefault();
  if (window.location.pathname !== '/') {
    window.location.href = '/#' + elementId;
    return;
  }
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ 
      behavior: 'smooth', 
      block: 'start'
    });
  }
};

const LanguageSwitch = () => {
  const { language, setLanguage } = useLanguage();
  
  return (
    <div style={{
      display: 'flex',
      borderRadius: '8px',
      border: '1px solid #3491FA',
      overflow: 'hidden',
    }}>
      <Button
        type={language === 'zh' ? 'primary' : 'text'}
        onClick={() => setLanguage('zh')}
        style={{
          fontSize: '14px',
          padding: '4px 8px',
          borderRadius: '0',
          color: language === 'zh' ? '#fff' : '#3491FA',
          background: language === 'zh' ? '#3491FA' : 'transparent',
          border: 'none',
          height: '28px',
          lineHeight: '20px',
        }}
      >
        中
      </Button>
      <div style={{
        width: '1px',
        background: '#3491FA',
      }} />
      <Button
        type={language === 'en' ? 'primary' : 'text'}
        onClick={() => setLanguage('en')}
        style={{
          fontSize: '14px',
          padding: '4px 8px',
          borderRadius: '0',
          color: language === 'en' ? '#fff' : '#3491FA',
          background: language === 'en' ? '#3491FA' : 'transparent',
          border: 'none',
          height: '28px',
          lineHeight: '20px',
        }}
      >
        EN
      </Button>
    </div>
  );
};

const App = () => {
  const [remainingWords, setRemainingWords] = useState(0);
  const [loginVisible, setLoginVisible] = useState(false);
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const { t } = useLanguage();

  const fetchRemainingWords = useCallback(async () => {
    if (isLoggedIn) {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoggedIn(false);
        return;
      }
      
      try {
        const response = await fetch(`${API_BASE_URL}/api/user/word-count`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (response.status === 401) {
          // Token is invalid or expired
          localStorage.removeItem('token');
          setIsLoggedIn(false);
          return;
        }
        
        if (response.ok) {
          const data = await response.json();
          setRemainingWords(data.remaining_words);
        } else {
          throw new Error('Failed to fetch word count');
        }
      } catch (error) {
        console.error('Error fetching remaining words:', error);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('userEmail');
    
    if (token) {
      setIsLoggedIn(true);
      setUserEmail(email || '');
      fetchRemainingWords();
    } else {
      setIsLoggedIn(false);
      setUserEmail('');
    }
  }, [fetchRemainingWords]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userEmail');
    setIsLoggedIn(false);
    setUserEmail('');
    window.location.href = '/'; // 退出后重定向到首页
  };

  // 移动端菜单组件
  const MobileMenu = () => (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      style={{
        position: 'absolute',
        top: '56px',
        left: 0,
        right: 0,
        background: 'white',
        padding: '10px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        zIndex: 999,
      }}
    >
      <Menu style={{ border: 'none' }}>
        <Menu.Item key="features" onClick={(e) => {
          scrollToElement('features')(e);
          setMobileMenuVisible(false);
        }}>
          Features
        </Menu.Item>
        <Menu.Item key="pricing" onClick={(e) => {
          scrollToElement('pricing')(e);
          setMobileMenuVisible(false);
        }}>
          Pricing
        </Menu.Item>
      </Menu>
    </motion.div>
  );

  const handleLoginRequired = useCallback(() => {
    setLoginVisible(true);
  }, []);

  useEffect(() => {
    // 处理 URL hash 变化
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    };

    // 监听 hash 变化
    window.addEventListener('hashchange', handleHashChange);
    // 初始加载时也检查
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <Router>
      <ConfigProvider>
        <div style={{ 
          margin: 0, 
          padding: 0, 
          boxSizing: 'border-box',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}>
          <nav style={{
            background: 'rgba(255, 255, 255, 0.9)',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
            padding: isMobile ? '10px 20px' : '10px 40px',
            height: '56px',
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
            }}>
              <div style={{ flexShrink: 0 }}>
                <a href="/" style={{ display: 'block' }}>
                  <img 
                    src='https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/logo_tab.webp' 
                    alt="Logo" 
                    style={{ height: '40px' }} 
                  />
                </a>
              </div>
              
              {!isMobile && (
                <ul style={{
                  listStyle: 'none',
                  display: 'flex',
                  margin: 0,
                  padding: 0,
                  marginLeft: '30px', 
                }}>
                  <li style={{ marginRight: '40px' }}>
                    <a href="#features" onClick={scrollToElement('features')} style={{ 
                      textDecoration: 'none', 
                      color: '#001A4D',
                      fontSize: '16px'
                    }}>
                      {t('nav.features')}
                    </a>
                  </li>
                  <li>
                    <a href="#pricing" onClick={scrollToElement('pricing')} style={{ 
                      textDecoration: 'none', 
                      color: '#001A4D',
                      fontSize: '16px'
                    }}>
                      {t('nav.pricing')}
                    </a>
                  </li>
                </ul>
              )}
            </div>

            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: isMobile ? '10px' : '20px'
            }}>
              {isMobile && (
                <Button
                  type="text"
                  icon={<IconMenu />}
                  onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                  style={{ padding: '4px' }}
                />
              )}
              
              <LanguageSwitch />
              
              {isLoggedIn ? (
                <>
                  {!isMobile && (
                    <span style={{ 
                      color: '#3491FA',
                      fontSize: '14px',
                      whiteSpace: 'nowrap',
                    }}>
                      {t('nav.tokens')}: {remainingWords}
                    </span>
                  )}
                  <Dropdown
                    droplist={
                      <Menu style={{ minWidth: '150px' }}>
                        {isMobile && (
                          <Menu.Item>
                            {t('nav.tokens')}: {remainingWords}
                          </Menu.Item>
                        )}
                        <Menu.Item key="logout" onClick={handleLogout}>
                          {t('nav.logout')}
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button 
                      type="text" 
                      style={{ 
                        color: '#3491FA',
                        fontSize: '14px',
                        padding: '4px 8px',
                        borderRadius: '8px',
                        maxWidth: isMobile ? '120px' : 'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {userEmail}
                    </Button>
                  </Dropdown>
                </>
              ) : (
                <Button 
                  type="outline" 
                  onClick={() => setLoginVisible(true)}
                  style={{
                    fontSize: '14px',
                    height: '32px',
                    padding: '0 15px',
                    borderRadius: '8px',
                    color: '#001A4D',
                    borderColor: '#001A4D'
                  }}
                >
                  {t('nav.login')}
                </Button>
              )}
            </div>
          </nav>
          
          <AnimatePresence>
            {isMobile && mobileMenuVisible && <MobileMenu />}
          </AnimatePresence>

          <Routes>
            <Route path="/" element={
              <>
                <div style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  padding: '20px 20px 100px',
                  background: 'radial-gradient(circle at center, rgba(52, 145, 250, 0.1) 35%, rgba(255, 255, 255, 1) 70%)',
                }}>
                  <div style={{
                    textAlign: 'center',
                    marginTop: '40px',
                    marginBottom: '40px',
                    width: '100%',
                    maxWidth: '750px',
                  }}>
                    <Title heading={2} style={{ 
                      color: TITLE_COLOR, 
                      marginBottom: '20px', 
                      fontSize: 'clamp(32px, 6vw, 40px)',
                      display: 'flex',              
                      flexDirection: 'column',     
                      alignItems: 'center',         
                      justifyContent: 'center',     
                      gap: isMobile ? '5px' : '10px', 
                      width: '100%', 
                    }}>
                      <motion.div
                        variants={typingContainer}
                        initial="hidden"
                        animate="show"
                        style={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: isMobile ? '5px' : '10px',
                          width: isMobile ? '100%' : 'auto',
                        }}
                      >
                        <div style={{ 
                          display: 'flex', 
                          gap: 0,
                          justifyContent: 'center'
                        }}>
                          {Array.from(t('hero.trusted')).map((char, index) => (
                            <motion.span
                              key={`trusted-${index}`}
                              variants={typingCharacter}
                              style={{ display: 'inline-block' }}
                            >
                              {char}
                            </motion.span>
                          ))}
                        </div>
                        
                        <motion.span style={{
                          color: '#3491FA',
                          borderRadius: '4px',
                          fontWeight: 'bold',
                          display: 'flex',
                          justifyContent: 'center',
                          whiteSpace: isMobile ? 'normal' : 'nowrap',
                          margin: isMobile ? '0' : '0 10px'
                        }}>
                          {Array.from(t('hero.bypass')).map((char, index) => (
                            <motion.span
                              key={`bypass-${index}`}
                              variants={typingCharacter}
                              style={{
                                display: 'inline-block',
                                whiteSpace: 'pre'
                              }}
                            >
                              {char}
                            </motion.span>
                          ))}
                        </motion.span>

                        <div style={{ 
                          display: 'flex', 
                          gap: 0,
                          justifyContent: 'center'
                        }}>
                          {Array.from(t('hero.tool')).map((char, index) => (
                            <motion.span
                              key={`tool-${index}`}
                              variants={typingCharacter}
                              style={{ display: 'inline-block' }}
                            >
                              {char}
                            </motion.span>
                          ))}
                        </div>
                      </motion.div>
                    </Title>

                    <motion.div
                      initial={{ opacity: 0, y: -30 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ 
                        duration: 0.8, 
                        delay: 2,                 // 调整段落的延迟
                        ease: [0.4, 0, 0.2, 1],
                      }}
                    >
                      <Paragraph style={{ 
                        color: TITLE_COLOR, 
                        fontSize: 'clamp(18px, 4vw, 18px)', 
                        wordBreak: 'keep-all', 
                        maxWidth: '750px', 
                        margin: '0 auto',
                      }}>
                        {t('hero.description')}
                      </Paragraph>
                    </motion.div>
                  </div>
                  <AITextChecker 
                    isLoggedIn={isLoggedIn} 
                    onWordsUpdate={(newWordCount) => {
                      setRemainingWords(newWordCount);
                    }}
                    remainingWords={remainingWords}
                  />
                </div>
                <div id="features" style={{
                  width: '100%',
                  background: '#E8F3FF',
                  padding: '30px 0',
                }}> 
                  <Features />
                </div>
                <div style={{
                  width: '100%',
                  padding: '30px 0',
                  background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(232,243,255,0.3) 50%, rgba(232,243,255,1) 100%)',
                }}>
                  <Pricing onLoginRequired={handleLoginRequired} />
                </div>
              </>
            } />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>

          <Footer />
        </div>
        <AnimatePresence>
          {loginVisible && (
            <Login 
              onSuccess={() => {
                setLoginVisible(false);
                setIsLoggedIn(true);
                setUserEmail(localStorage.getItem('userEmail'));
                fetchRemainingWords();
              }}
              onClose={() => setLoginVisible(false)}
            />
          )}
        </AnimatePresence>
      </ConfigProvider>
    </Router>
  );
};

export default App;
