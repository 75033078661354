import React from 'react';
import { Typography, Grid } from '@arco-design/web-react';
import { useLanguage } from '../contexts/LanguageContext';

const { Title, Paragraph } = Typography;
const { Row, Col } = Grid;

const Feature = ({ title, description, imageSrc1, imageSrc2 }) => (
  <div style={{
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '20px',
    padding: '30px',
    marginBottom: '60px',
    boxShadow: '0 4px 5px rgba(0,0,0,0.05)'
  }}>
    <Row justify="center" style={{ marginBottom: '20px' }}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Title heading={4} style={{ marginBottom: '15px', color: '#001A4D', fontSize: '24px' }}>
          {title}
        </Title>
        <Paragraph style={{ color: '#666', fontSize: '16px' }}>
          {description}
        </Paragraph>
      </Col>
    </Row>
    <Row gutter={[20, 20]} align="middle">
      <Col xs={24} sm={12}>
        <img src={imageSrc1} alt={title + " 1"} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
      </Col>
      <Col xs={24} sm={12}>
        <img src={imageSrc2} alt={title + " 2"} style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
      </Col>
    </Row>
  </div>
);

const Features = () => {
  const { t } = useLanguage();

  const features = [
    {
      title: t('features.detection.title'),
      description: (
        <>
          {t('features.detection.description')}
        </>
      ),
      imageSrc1: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/aigc_cnki.webp',
      imageSrc2: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/aigc_turnitin.webp',
    },
    {
      title: t('features.rewriting.title'),
      description: (
        <>
          {t('features.rewriting.description')}
        </>
      ),
      imageSrc1: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/line_cnki.webp',
      imageSrc2: 'https://ushallpassmainland-1305187289.cos.accelerate.myqcloud.com/line_turnitin.webp',
    },
  ];

  return (
    <div id="features" style={{ width: '100%', maxWidth: '1200px', margin: '60px auto' }}>
      {features.map((feature, index) => (
        <Feature key={index} {...feature} />
      ))}
    </div>
  );
};

export default Features;
