import React from 'react';
import { Typography, Message, Card, Button } from '@arco-design/web-react';
import { API_BASE_URL } from '../config';
import { useLanguage } from '../contexts/LanguageContext';

const { Title, Text } = Typography;

const PriceCard = ({ price, tokens, isTestMode, onSelect }) => {
  const { t } = useLanguage();
  
  return (
    <Card
      style={{
        width: '300px',
        borderRadius: '12px',
        boxShadow: '0 4px 16px rgba(0, 0, 0, 0.05)',
        background: 'white',
        border: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        minHeight: '240px',
        padding: '24px',
      }}
      className="price-card"
    >
      <div style={{
        marginBottom: 'auto'
      }}>
        {isTestMode && (
          <div style={{
            background: '#E8F3FF',
            padding: '4px 12px',
            borderRadius: '16px',
            display: 'inline-block',
            marginBottom: '16px',
            color: '#3491FA',
            fontSize: '14px',
            fontWeight: '500',
          }}>
            {t('pricing.bestDeal')}
          </div>
        )}
        <Text style={{ 
          fontSize: '30px',
          fontWeight: 'bold',
          display: 'block',
          marginBottom: '8px',
          color: '#001A4D'
        }}>
          {tokens} {t('pricing.tokens')}
        </Text>
        <Text style={{ 
          fontSize: '20px',
          display: 'block',
          marginBottom: '24px',
          color: '#666'
        }}>
          HKD ${price}
        </Text>
      </div>
      
      <Button 
        type="primary" 
        long 
        onClick={onSelect}
        style={{
          height: '44px',
          fontSize: '20px',
          borderRadius: '8px',
          background: '#3491FA',
          border: 'none'
        }}
      >
        {t('pricing.pay')}
      </Button>
    </Card>
  );
};

const Pricing = ({ onLoginRequired }) => {
  const { t } = useLanguage();
  const token = localStorage.getItem('token');

  // 定义价格方案
  const pricePlans = [
    { id: "price_1QFuu6KBjaieGBZ5XoGC0Uqk", tokens: 10, price: 5, isTestMode: false },
    { id: "price_1QFYNWKBjaieGBZ54yHD8j8k", tokens: 1000, price: 20, isTestMode: false },
    { id: "price_1QJeLvKBjaieGBZ5aXJB5gQT", tokens: 5000, price: 80, isTestMode: true },
  ];

  const handlePriceSelect = async (priceId) => {
    if (!token) {
      onLoginRequired?.();
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          priceId: priceId
        })
      });

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }

      window.location.href = data.url;
    } catch (error) {
      Message.error(t('pricing.errors.checkoutFailed'));
      console.error(error);
    }
  };

  return (
    <div id="pricing" style={{ 
      width: '100%', 
      maxWidth: '1200px', 
      margin: '60px auto',
      padding: '0 20px',
      position: 'relative',
    }}>
      <Title 
        heading={2} 
        style={{ 
          textAlign: 'center', 
          marginBottom: '40px', 
          color: '#001A4D',
          fontSize: '30px',
        }}
      >
        {t('pricing.title')}
      </Title>
      
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '24px',
        flexWrap: 'wrap',
      }}>
        {pricePlans.map((plan) => (
          <PriceCard
            key={plan.id}
            tokens={plan.tokens}
            price={plan.price}
            isTestMode={plan.isTestMode}
            onSelect={() => handlePriceSelect(plan.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default Pricing;