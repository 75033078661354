import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Typography, Message, Skeleton } from '@arco-design/web-react';
import { IconUndo, IconAlignLeft, IconPaste, IconRight } from '@arco-design/web-react/icon';
import { TITLE_COLOR } from '../App';
import { useMediaQuery } from '@react-hook/media-query';
import { motion } from 'framer-motion';
import { useLanguage } from '../contexts/LanguageContext';

const { TextArea } = Input;
const { Paragraph } = Typography;

const AITextChecker = ({ isLoggedIn, onWordsUpdate, remainingWords }) => {
  const { t } = useLanguage();
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [isOutputComplete, setIsOutputComplete] = useState(false);
  const [detector, setDetector] = useState('turnitin');
  const streamingRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSteps, setShowSteps] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [stepsStatus, setStepsStatus] = useState(['process', 'process', 'process', 'process']);

  const textAreaStyle = {
    width: '100%',
    minHeight: '300px',
    backgroundColor: 'white',
    border: '1px solid #D9D9D9',
    borderRadius: '10px',
    fontSize: 'clamp(14px, 3vw, 16px)',
    padding: '10px',
  };

  const cnkiSampleText = "本研究的目的在于探索创新激励机制，以激发知识型员工潜能，提升企业的竞争力。通过深入了解知识型员工的特点和需求，结合现代管理理念和方法，构建一套科学、合理、有效的激励机制。具体而言，我们希望通过创新激励机制，为知识型员工提供更多的发展机会和空间满足他们的自我实现需求；同时，通过激励机制的优化，提高知识型员工的工作积极性和创新能力为企业创造更多的值。例如，我们可以借鉴一些成功企业的经验。这些企业通过采用灵活的工作制度、丰富的培训资源以及多元化的激励方式，成功吸引和留住了大量的知识型人才，提升了企业的核心竞争力。";
  const turnitinSampleText = "Gliomas are the most common and aggressive type of primary brain tumors, arising from glial cells in the central nervous system (CNS). These tumors are classified by the World Health Organization (WHO) into grades I to IV, with glioblastoma multiforme (GBM) being the most malignant and deadly form. Despite advances in medical science, the prognosis for glioma patients remains poor, with median survival rates often less than two years for high-grade gliomas such as GBM.";

  const CustomRadioGroup = ({ value, onChange, children }) => {
    return (
      <div style={{ display: 'flex', gap: '16px' }}>
        {React.Children.map(children, child => 
          React.cloneElement(child, { 
            checked: value === child.props.value,
            onChange: () => onChange(child.props.value)
          })
        )}
      </div>
    );
  };

  const CustomRadio = ({ value, checked, onChange, children }) => {
    return (
      <div 
        onClick={onChange}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        <div style={{
          width: '12px',
          height: '12px',
          borderRadius: '50%',
          border: `1.5px solid ${checked ? '#3491FA' : '#C9CDD4'}`,
          backgroundColor: checked ? '#3491FA' : 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'all 0.2s',
        }}>
          {checked && (
            <div style={{
              width: '4px',
              height: '4px',
              borderRadius: '50%',
              backgroundColor: 'white',
            }} />
          )}
        </div>
        <span style={{
          color: '#001A4D',
          fontSize: '14px',
        }}>
          {children}
        </span>
      </div>
    );
  };

  const CustomSteps = ({ steps, currentStep }) => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '20px',
        width: '110%',
      }}>
        {steps.map((step, index) => {
          const isCompleted = index < currentStep;
          const isCurrent = index === currentStep;
          
          return (
            <div key={index} style={{
              display: 'flex',
              alignItems: 'center',
              flex: 1,
              minWidth: 0,
            }}>
              {/* 步骤点和文字容器 */}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                flex: 1,
                minWidth: 0,
              }}>
                {/* 步骤圆圈 */}
                <div style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: isCompleted ? '#E8F3FF' : isCurrent ? '#3491FA' : '#F2F3F5',
                  color: isCompleted ? '#3491FA' : isCurrent ? 'white' : '#86909C',
                  fontSize: '14px',
                  flexShrink: 0,
                  transition: 'all 0.3s',
                }}>
                  {isCompleted ? '✓' : index + 1}
                </div>
                {/* 文字容器 */}
                <span style={{
                  fontSize: '14px',
                  color: isCompleted || isCurrent ? '#4E5969' : '#86909C',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minWidth: '100px',
                  flex: 1,
                }}>
                  {step}
                </span>
              </div>
              
              {/* 连接线 */}
              {index < steps.length - 1 && (
                <div style={{
                  height: '1px',
                  width: '20px',
                  backgroundColor: isCompleted ? '#3491FA' : '#E5E6E8',
                  margin: '0 1px',
                  flexShrink: 0,
                  transition: 'all 0.3s',
                }} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const handleSampleClick = () => {
    setInputText(detector === 'turnitin' ? turnitinSampleText : cnkiSampleText);
  };

  const handlePasteText = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInputText(text);
    } catch (err) {
      Message.error(t('aiChecker.errors.clipboardAccess'));
    }
  };

  useEffect(() => {
    if (streamingRef.current) {
      streamingRef.current.scrollTop = streamingRef.current.scrollHeight;
    }
  }, [outputText]);

  const handleSubmit = async () => {
    if (!isLoggedIn) {
      Message.error(t('aiChecker.errors.loginRequired'));
      return;
    }

    if (!inputText.trim()) {
      Message.error(t('aiChecker.errors.emptyText'));
      return;
    }

    const inputTokens = calculateTokens(inputText, detector === 'turnitin');

    // 检查剩余字数是否足够
    if (remainingWords < inputTokens) {
      Message.error(
        t('aiChecker.errors.insufficientWords', {
          required: inputTokens,
          remaining: remainingWords
        })
      );
      return;
    }

    setLoading(true);
    setShowOutput(true);
    setOutputText('');
    setIsOutputComplete(false);
    setCurrentStep(0);
    setShowSteps(true);
    setShowSkeleton(true);
    setStepsStatus(['process', 'process', 'process', 'process']);

    const steps = [
      t('aiChecker.steps.understand'),
      t('aiChecker.steps.check'),
      t('aiChecker.steps.compute'),
      t('aiChecker.steps.final')
    ];

    // 为 Turnitin 提供 1.5 倍的处理时间
    const totalDuration = inputText.length * 60 * (detector === 'turnitin' ? 1.5 : 1);
    const stepDuration = totalDuration / steps.length;

    for (let i = 0; i < steps.length; i++) {
      setCurrentStep(i);
      await new Promise(resolve => setTimeout(resolve, stepDuration));
      setStepsStatus(prev => prev.map((status, index) => 
        index <= i ? 'finish' : 'process'
      ));
    }

    const apiUrl = 'https://leahloveswriting.xyz';
    const endpoint = detector === 'turnitin' ? '/api/rewrite_turnitin' : '/api/rewrite';
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 90000);

      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text: inputText }),
        signal: controller.signal
      });

      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      
      setOutputText(data.result);
      setIsOutputComplete(true);
      
      // 更新剩余字数
      if (data.remaining_words !== undefined) {
        onWordsUpdate?.(data.remaining_words);
      }
    } catch (error) {
      console.error('Error:', error);
      Message.error(`Request failed: ${error.message}`);
      setOutputText(`Error: ${error.message}`);
    } finally {
      setLoading(false);
      setShowSteps(false);
      setShowSkeleton(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(outputText).then(() => {
      Message.success(t('aiChecker.messages.copied'));
    });
  };

  const handleDetectorChange = (value) => {
    setDetector(value);
  };

  const handleUndo = () => {
    setInputText('');
    setOutputText('');
    setShowOutput(false);
    setIsOutputComplete(false);
  };

  // const emojiStyle = {
  //   transform: 'scale(1.5)',
  //   display: 'inline-block',
  //   marginBottom: '5px',
  // };

  const isMobile = useMediaQuery('(max-width: 767px)');

  const calculateTokens = (text, isEnglish) => {
    if (!text) return 0;
    const cleanText = text.trim();
    if (isEnglish) {
      // English: 4 characters = 1 token
      return Math.ceil(cleanText.length / 4);
    } else {
      // Chinese: 1 character = 1 token
      return cleanText.length;
    }
  };

  return (
    <div style={{ position: 'relative', maxWidth: '800px', width: '100%' }}>
      {/* 主内容 */}
      <motion.div
        initial={{ opacity: 0, y: -40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ 
          duration: 0.8, 
          delay: 2.5,                 // 在 paragraph 之后显示
          ease: [0.4, 0, 0.2, 1],
        }}
        style={{
          background: 'rgba(255, 255, 255, 0.7)',
          borderRadius: '20px',
          padding: '20px',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div style={{ position: 'relative', marginBottom: '20px' }}>
          <TextArea
            style={textAreaStyle}
            maxLength={1000}
            value={inputText}
            onChange={(value) => setInputText(value)}
          />
          {!inputText && (
            <>
              <div style={{
                position: 'absolute',
                left: '10px',
                top: '10px',
                fontSize: 'clamp(14px, 3vw, 16px)',
                color: '#86909C',
              }}>
                {t('aiChecker.placeholder')}
              </div>
              <div style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                gap: '10px',
              }}>
                <Button
                  onClick={handleSampleClick}
                  style={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    border: '1px solid #E5E6E8',
                    borderRadius: '8px',
                    color: '#86909C',
                    fontWeight: 'normal',
                    fontSize: 'clamp(12px, 2.5vw, 14px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#F7F8FA',
                    textAlign: 'center',
                  }}
                >
                  <IconAlignLeft style={{ fontSize: '24px', marginBottom: '5px', marginRight: '5px' }} />
                  <span style={{ transform: 'translateX(-5px)' }}>
                    {t('aiChecker.buttons.sample')}
                  </span>
                </Button>
                <Button
                  onClick={handlePasteText}
                  style={{
                    width: '100px',
                    height: '100px',
                    padding: '10px',
                    border: '1px solid #E5E6E8',
                    borderRadius: '8px',
                    color: '#86909C',
                    fontWeight: 'normal',
                    fontSize: 'clamp(12px, 2.5vw, 14px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#F7F8FA',
                    textAlign: 'center',
                  }}
                >
                  <IconPaste style={{ fontSize: '24px', marginBottom: '5px', marginRight: '5px' }} />
                  <span style={{ transform: 'translateX(-5px)' }}>
                    {t('aiChecker.buttons.paste')}
                  </span>
                </Button>
              </div>
            </>
          )}
          <div style={{
            position: 'absolute',
            right: '10px',
            bottom: '10px',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            fontSize: 'clamp(12px, 2.5vw, 14px)',
            color: '#86909C',
          }}>
            {isOutputComplete && (
              <IconUndo
                style={{ cursor: 'pointer' }}
                onClick={handleUndo}
              />
            )}
            <span>{calculateTokens(inputText, detector === 'turnitin')} tokens</span>
          </div>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: '10px',
          marginBottom: '20px',
        }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '10px', 
            flexWrap: 'wrap',
            width: isMobile ? '100%' : 'auto',
          }}>
            <span style={{ 
              color: TITLE_COLOR, 
              fontWeight: 'bold', 
              fontSize: 'clamp(14px, 3vw, 16px)' 
            }}>
              {t('aiChecker.detector')}:
            </span>
            <CustomRadioGroup
              value={detector}
              onChange={handleDetectorChange}
            >
              <CustomRadio value="turnitin">
                {t('aiChecker.detectors.turnitin')}
              </CustomRadio>
              <CustomRadio value="cnki">
                {t('aiChecker.detectors.cnki')}
              </CustomRadio>
            </CustomRadioGroup>
          </div>
          <Button 
            type="primary" 
            onClick={handleSubmit} 
            loading={loading}
            disabled={!inputText.trim() || loading}
            style={{
              height: '40px',
              fontSize: 'clamp(14px, 3vw, 16px)',
              fontWeight: 600,
              borderRadius: '10px',
              background: '#3491FA',
              border: 'none',
              minWidth: '120px',
              width: isMobile ? '100%' : 'auto',
              marginTop: isMobile ? '10px' : '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
            }}
          >
            {t('aiChecker.buttons.startRewriting')} <IconRight />
          </Button>
        </div>
        {showOutput && (
          <div 
            ref={streamingRef}
            style={{
              background: 'white',
              borderRadius: '10px',
              padding: '20px',
              minHeight: '200px',
              maxHeight: '400px',
              overflowY: 'auto',
              marginTop: '20px',
              border: '1px solid #D9D9D9',
              position: 'relative',
            }}
          >
            {showSteps && (
              <CustomSteps
                steps={[
                  t('aiChecker.steps.understand'),
                  t('aiChecker.steps.check'),
                  t('aiChecker.steps.compute'),
                  t('aiChecker.steps.final')
                ]}
                currentStep={currentStep}
              />
            )}
            {showSkeleton && (
              <div style={{
                opacity: isOutputComplete ? 0 : 1,
                transition: 'opacity 1s ease-out',
              }}>
                <Skeleton text={{ rows: 4 }} animation />
              </div>
            )}
            {isOutputComplete && (
              <div style={{
                opacity: isOutputComplete ? 1 : 0,
                transition: 'opacity 1s ease-in',
              }}>
                <Paragraph 
                  copyable={{ text: outputText, onCopy: copyToClipboard }} 
                  style={{ 
                    fontSize: 'clamp(14px, 3vw, 16px)', 
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word'
                  }}
                >
                  {outputText || t('aiChecker.messages.waiting')}
                </Paragraph>
              </div>
            )}
            <div style={{
              position: 'absolute',
              right: '10px',
              bottom: '10px',
              fontSize: 'clamp(12px, 2.5vw, 14px)',
              color: '#86909C',
            }}>
              {isOutputComplete ? `${outputText.length}/${t('aiChecker.messages.maxLength')}` : '0/1000'}
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default AITextChecker;
