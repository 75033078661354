import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';

const Footer = () => {
  const { t } = useLanguage();
  
  return (
    <footer style={{
      borderTop: '1px solid #eee',
      padding: '20px',
      textAlign: 'center',
      marginTop: 'auto'
    }}>
      <div>
        <Link 
          to="/terms" 
          style={{ 
            color: '#3491FA',
            textDecoration: 'none',
            margin: '0 10px'
          }}
        >
          {t('footer.terms')}
        </Link>
        <Link 
          to="/privacy" 
          style={{ 
            color: '#3491FA',
            textDecoration: 'none',
            margin: '0 10px'
          }}
        >
          {t('footer.privacy')}
        </Link>
      </div>
      <div style={{ 
        marginTop: '10px',
        color: '#666',
        fontSize: '14px'
      }}>
        <span>© {new Date().getFullYear()} Ushallpass.ai</span>
      </div>
    </footer>
  );
};

export default Footer;